.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

.times {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.times div {
  margin: 5px;
}
