/* styles.css */
body {
    font-family: 'Palatino', serif;
    background: linear-gradient(to top, #0B3D91, #0A2849, #0A0A26, #0A0A0A);
    height: 100vh; /* Make sure the gradient covers the full viewport height */
    color: #333; /* Dark gray for readability */
    margin: 0;
    padding: 0;
}

.app {
  text-align: center;
  padding: 20px;
}

.header {
  display: flex;
  align-items: center;  /* Centers content vertically */
  justify-content: space-between;
  margin: 0px 20px 3px;  /* Padding on the sides */
}

h1 {
  font-size: 2em;
}

.headerTime {
  align-self: flex-end;  /* Aligns span at the bottom */
  font-weight: bold;
}

.dark-mode-text-color {
  color: #f0f0f0;
}

.controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 40px;
  position: relative; /* Ensures absolute positioning is relative to this container */
}

.date-nav {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

button {
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #777777; /* Darker orange on hover */
  border-radius: 5px;
}

.location-form {
  display: flex;
  align-items: center;
  gap: 10px;
}


.svg-container {
  text-align: center;
  padding-top: 40px;
}

svg {
  max-width: 100%; /* Responsive width */
  max-height: 400px; /* Set a max height */
}

.date-picker-popup {
  position: absolute; /* Allows the date picker to hover */
  top: 20px; /* Adjust based on the controls' height */
  right: 0;
  transform: translateX(-50%); /* Centers the date picker horizontally */
  z-index: 1; /* Ensures it appears on top of other elements */
  background-color: white; /* Ensure background is opaque */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.circadian-events-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px; /* Adjust the gap for spacing between elements */
}

.circadian-event {
  display: flex;
  justify-content: flex-start;
  gap: 5px; /* Spacing between label and date */
  transition: background-color 0.3s, border-color 0.3s; /* Smooth transition */
}

.circadian-event-highlight {
  color: #ff5733; /* Change text color on hover */
}

.circadian-event:hover {
  color: #ff5733; /* Change text color on hover */
}

.label {
  font-weight: bold; /* Bold text for labels */
  text-align: right; /* Right-align the labels to align colons */
}

.date {
  flex: 1; /* Ensures dates take up the rest of the space */
  text-align: left; /* Right-align the labels to align colons */
}

/* Navigation */

.nav-wrapper-light {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 8px;
  border: 1px solid #ddd;
}

.nav-item-light {
  cursor: pointer;
  padding: 8px 16px;
  transition: background-color 0.3s ease;
}

.nav-item-light:hover {
  background-color: #e0e0e0;
}

.nav-item-light:active {
  background-color: #cfcfcf;
}

.nav-item-selected-light {
  cursor: pointer;
  padding: 8px 16px;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

/* Base wrapper style for dark backgrounds */
.nav-wrapper-dark {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 9px;
  background-color: transparent; /* Transparent to blend with various dark backgrounds */
}

/* Base style for nav items over dark backgrounds */
.nav-item-selected-dark {
  cursor: pointer;
  padding: 8px 16px;
  transition: background-color 0.3s ease;
  color: #ffffff; /* Light text for contrast */
  font-weight: bold; /* Highlight the selected item */
}

/* Hover effect for nav items over dark backgrounds */
.nav-item-dark:hover {
  background-color: #333; /* Slightly brighter on hover */
}

/* Active effect for nav items over dark backgrounds */
.nav-item-dark:active {
  background-color: #555; /* Even brighter when active */
}

/* Style for a selected item over dark backgrounds */
.nav-item-dark {
  cursor: pointer;
  padding: 8px 16px;
  transition: background-color 0.3s ease;
  color: #f0f0f0; /* White text for selected items */
}

.nav-wrapper-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 9px;
  border: 1px transparent;
}

.nav-item-loading {
  cursor: pointer;
  padding: 8px 16px;
  transition: background-color 0.3s ease;
  color: transparent;
}
